.vacancyCard{
  padding: 20px;
  width: calc(50% - 10px);
  border: 1px solid #000;
  position: relative;
  border-radius: 4px;

  h2{
    margin: 20px 0 40px 0;
    font-size: 30px;

    @media screen and (max-width: 550px){
      margin: 20px 0;
    }
  }

  h3{
    font-size: 16px;
    margin-bottom: 8px;
  }

  p{

    &:not(:last-child){
      margin-bottom: 20px;
    }
  }

  a{
    display: block;
    text-align: center;
    font-family: Oswald, sans-serif;
    padding: 10px 50px;
    background-color: #AFCA0B;
    color: #000;
    text-decoration: none;
    font-size: 24px;
    font-weight: 600;
    max-width: 200px;
    margin: 35px auto 0 auto;
  }

  .additional{
    position: absolute;
    top: 20px;
    right: 20px;
    font-weight: 600;

    p{
      display: flex;
      align-items: center;
      margin: 0 0 10px 0;
    }

    img{
      margin-right: 10px;
    }

    @media screen and (max-width: 550px) {
      position: initial;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 1000px){
    width: 100%;
  }
}

