.hover-slider-wrapper {
    position: relative;

    & *::selection {
        background: transparent;
        color: inherit;
    }

    .hover-slider__scroll-down-btn {
        position: absolute;
        bottom: 16px;
        &::-moz-selection {
        background:rgba(255, 255, 125, 0.99);
        color:#032764;
    }
    &::-webkit-selection {
        background:rgba(255, 255, 125, 0.99);
        color:#032764;
    }
    &::selection {
        background:rgba(255, 255, 125, 0.99);
        color:#4477d1;
    }
        width: 200px;
        left: calc(50% - 200px/2);
        z-index: 10;
        background-color: transparent;
        border: none;
        font-style: normal;
        cursor: pointer;
        font-family: Oswald;
        outline: none;
        font-size: 24px;
        line-height: 36px;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            padding-top: 8px;
        }
        animation: 1s infinite bouncing;
    }
}

@keyframes bouncing {
    0% {
        bottom: 16px;
    }
    50% {
        bottom: 26px;
    }
    100% {
        bottom: 16px;
    }
}

.hover-slider {
    display: flex;
    &-item {
        -webkit-box-shadow: 0px -19px 40px -12px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px -19px 40px -12px rgba(0,0,0,0.75);
        box-shadow: 0px -19px 40px -12px rgba(0,0,0,0.75);
        height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        width: 0%;
        align-items: center;
        justify-content: center;
        transition: width .3s;
        &__content {
            max-width: 818px;
            display: none;
            text-align: center;
            color: #FFFFFF;
        }
        &__title {
            font-family: Oswald, serif;
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 72px;
            padding-bottom: 15px;
        }
        &__subtitle {
            margin-bottom: 64px;
            font-family: Montserrat, serif;
            width: 818px;
            font-size: 24px;
            line-height: 29px;
        }
        &_active {
            width: 90%;
            position: relative;
            background-size: cover;
            .hover-slider-item__content {
                display: block;
            }
        }
        &_close {
            cursor: pointer;
            width: 10%;
            &:hover {
                width: 11%;
            }
        }
    }
    &-item:first-child {
        .hover-slider-item__content {
            margin-left: 11%;
        }
        &.hover-slider-item_active::before {
            display: none;
        }
    }
    &-item:last-child {
        .hover-slider-item__content {
            margin-right: 11%;  
        }
        &.hover-slider-item_active::after {
            display: none;
        }
    }
}

@media screen and (min-width: 1921px) {
    .hover-slider-item {
        background-size: cover;
    }
}

.hover-slider-controls {
    position: absolute;
    bottom: 44px;
    z-index: 10;
    width: 100%;
    &__prev,
    &__next {
        cursor: pointer;
        outline: none;
        background-color: transparent;
        border: none;
        width: 168px;
        height: 72px;
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        transition: opacity .2s;
        &:hover {
            opacity: .8;
        }
    }
    &__prev {
        left: 5%;
        bottom: 44px;
        background-image: url('../../assets/prev.svg');
    }
    &__next {
        bottom: 44px;
        right: 5%;
        background-image: url('../../assets/next.svg');
    }
    .hover-slider-item__content {
        display: block;
    }
}

@media screen and (max-width: 1200px) {
    .hover-slider {
        &-item {
            &__content {
                max-width: 618px;
            }
            &__title {
                margin-bottom: 16px;
                font-size: 32px;
                padding-bottom: 15px;
            }
            &__subtitle {
                margin-bottom: 64px;
                width: auto;
                font-size: 22px;
                line-height: 29px;
            }
        }
    }
}


// mobile device

@media screen and (max-width: 680px) {
    .hover-slider {
        
        .btn.btn-md {
            min-width: 160px;
            font-size: 16px;
        }
        &-item {
            height: 90vh;
            &__title {
                margin-bottom: 16px;
                font-size: 22px;
                padding-bottom: 15px;
                padding: 0 15px;
            }
            &__subtitle {
                margin: auto;
                margin-bottom: 33px;
                width: 320px;
                font-size: 16px;
                line-height: 18px;
            }
            &_active {
                width: 100%;
            }
            &_close {
                width: 0%;
                &:hover {
                    width: 0%;
                }
            }
        }
        &-item:first-child {
            .hover-slider-item__content {
                margin-left: 0%;
            }
        }
        &-item:last-child {
            .hover-slider-item__content {
                margin-right: 0%;  
            }
        }
    }

    .hover-slider-controls {
        bottom: 24px;
        &__prev,
        &__next {
            width: calc(168px/2);
            height: calc(72px/2);
            background-size: cover;
        }
        &__prev {
            left: 5%;
            bottom: 4px;
        }
        &__next {
            bottom: 4px;
            right: 5%;
        }
    }

    .hover-slider-wrapper .hover-slider__scroll-down-btn {
        width: 80px; 
        left: calc(50% - 80px/2); 
        font-size: 16px; 
        line-height: 18px; 
    }
    
}

@media screen and (max-height: 500px) {
    .hover-slider-item {
        height: 600px;
    }
        .header-mob_open .nav_open {
            top: 15%;
        .nav-list__item {
            font-size: 18px;
        }
    }
    .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 200px !important;
    }
    .image-gallery-thumbnails-container {
        display: none;
    }
    .modal-content {
        top: 30% !important;
    }
}