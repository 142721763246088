.image-gallery-custom-right-nav,
.image-gallery-custom-left-nav {
    background-color: transparent;
    border: none;
    position: absolute;
    top: calc(50% - 56px/2);
    cursor: pointer;
    outline: none;
    &:hover {
        opacity: .8;
    }
}
.image-gallery-custom-right-nav {
    right: -54px;
}
.image-gallery-custom-left-nav {
    left: -54px;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
    border: 4px solid #fff;
}
.image-gallery-thumbnail {
    width: unset;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 100px;
    width: unset;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
   height: 624px;
}

@media screen and (max-width: 1200px) {
    .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 424px;
     }
}
@media screen and (max-width: 600px) {
    .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 274px;
    }
    .image-gallery-thumbnail .image-gallery-thumbnail-image {
        height: 60px;
    }
    .image-gallery-custom-right-nav {
        right: -34px;
    }
    .image-gallery-custom-left-nav {
        left: -34px;
    }
}