.alert {
    position: fixed;
    z-index: 11;
    max-width: 500px;
    width: 100%;
    text-align: center;
    padding: 20px 5px;
    border-radius: 4px;
    top: -100%;
    background-color: #111111;
    color: #fff;
    font-size: 20px;
    left: 100px;
    -webkit-animation: fadein 4s ease 0s;
    animation: fadein 4s ease 0s;
}
@keyframes fadein {
    0% {
        opacity: 1;
    }
    20% {
        top: 100px;
    }
    80% {
        opacity: 1;
        top: 100px;
    }
    100% {
        opacity: 0;
        top: 100px;
    }
}
@media screen and (max-width: 600px) {
    .alert {
        width: 98%;
        left: 1%;
        right: 1%;
    }
}