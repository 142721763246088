@import "~react-image-gallery/styles/scss/image-gallery.scss";
.equipment-wrapper {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 8px;
  background-color: #fff;
  color: #444;
}
  
  .equipment__item {
    background-size: cover;
    cursor: pointer;
    background-color: #444;
    color: #fff;
    height: 400px;
    padding: 20px;
    font-size: 150%;
    transition: filter .3s;
    &:hover {
      filter: brightness(0.5);
    }
  }

@media screen and (max-width: 1200px) {
  .equipment__item {
    background-size: cover;
    background-position: center;
    height: 300px;
  }
}
@media screen and (max-width: 600px) {
  .equipment-wrapper {
    grid-gap: 1px;
    grid-template-columns: 50% 50%;
    .equipment__item {
      height: 180px;
    }
  }
}