.select-wrapper {
    margin-bottom: 31px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    padding-bottom: 6px;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #99999990;
    border: none;
    transition: all .3s;
    border-bottom: 1px solid #11111160;
    cursor: pointer;
    position: relative;
    &_invalid {
        .select-header-title {
            color: tomato;
        }
        border-bottom: 1px solid tomato;
    }
    .arrow-icon {
        position: absolute;
        right: 5px;
        transition: transform .2s;
        top: 0px;
        &_active {
            transform: rotate(180deg);
        }
        &_invalid path {
            fill: tomato;
        }
    }
}

.select-list {
    position: absolute;
    background-color: #fff;
    width: 100%;
    z-index: 5;
    margin-top: 5px;
    box-shadow: 0px 6px 7px 3px #ccc;
    // -webkit-box-shadow: 3px 3px 3px 6px #ccc;  
    // -moz-box-shadow:    3px 3px 3px 6px #ccc;
    &-item {
        transition: all .3s;
        padding: 5px 0px 5px 5px;
        &:hover {
            background-color: rgb(235, 235, 235);
        }
    }
}