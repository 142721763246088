.burger {
    width: 30px;
    height: 15px;
    position: relative;
    cursor: pointer;
    transition: transform .3s;
    &::before,
    &::after {
        transition: all .3s;
        transition-delay: .3s;
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #ffffff;
    }
    &::before {
        top: 0;
      }
    &::after {
        bottom: 0;
    }

    &_active {
        transform: rotate(90deg);
        &::before {
            transform: rotate(45deg);
            top: 5px;
          }
        &::after {
            transform: rotate(-45deg);
            bottom: 7px;
        }
    }
  }

  