.nav-list {
  display: flex;

  &__item a {
    cursor: pointer;
    border-bottom: 2px solid transparent;
    padding: 3px 4px;
    transition: border .3s;
  }

    .router-link{
        text-decoration: none;
        color: #fff;
    }

  &__item a:hover,
  &__item a.active {
    border-color: #ffffff;
  }

  &__item:not(:last-child) {
    margin-right: 74px;
  }
}

@media screen and (max-width: 1200px) {
  .nav-list {
    &__item:not(:last-child) {
      margin-right: 24px;
    }
  }
}

@media screen and (max-width: 768px) {
  .nav {
    display: none;

    &_open {
      display: block;
    }
  }
}
