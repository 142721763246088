.services {
    display: grid;
    grid-template-columns: calc(50% - 4px) calc(50% - 4px);
    grid-gap: 8px;
  }
  
  .services-item {
    background-size: cover;
    background-color: #fff;
    position: relative;
    height: 600px;
    &:hover {
        .services-item__bg {
            height: 140px;
        }
    }
    &__bg {
        transition: height .3s;
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100%;
        width: 100%;
        z-index: 2;
    }
    &__title {
        z-index: 3;
        position: absolute;
        top: 40px;
        font-family: Oswald;
        font-weight: 600;
        font-size: 40px;
        line-height: 59px;
        text-align: center;
        width: 100%;
        color: #fff;
    }
    &__description {
        color: #111;
        position: absolute;
        bottom: 46px;
        padding: 0 100px;
        font-size: 24px;
        line-height: 29px;
    }

    &_large {
        .services-item__title {
            max-width: 510px;
            left: calc(50% - 510px/2);
        }
        &:hover {
            .services-item__bg {
                height: 200px;
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    .services {
      .services-item {
        &__title {
            font-size: 30px; 
            line-height: 49px; 
        }
        &__description {
            padding: 0 25px; 
            font-size: 22px; 
        }
    
        &_large {
            .services-item__title {
                max-width: 400px; 
                left: calc(50% - 400px/2); 
            }
        }
      }
  }
}

@media screen and (max-width: 868px) {
    .services {
        .services-item {
            height: 300px;
            &:hover {
                .services-item__bg {
                    height: 60px;
                }
            }
            &__title {
                font-size: 24px; 
                line-height: 35px; 
                top: 10px;
            }
            &__description {
                bottom: 10px;
                padding: 0 25px; 
                font-size: 16px; 
                line-height: 18px;
            }
      
            &_large {
                .services-item__title {
                    max-width: 375px;
                    left: calc(50% - 375px/2); 
                }
                &:hover {
                    .services-item__bg {
                        height: 90px;
                    }
                }
            }
        }
    }   
}

@media screen and (max-width: 600px) {
    .services {
        display: block;
        .services-item {
            height: 250px;
            &__description {
                padding: 0 10px;
            }
        }
    }

}