* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

}
h1 {
  margin: 0;
}
body {
  font-family: 'Montserrat', sans-serif;
  color: #111111;
  overflow-x: hidden;
}
#root {
  position: relative;
}
ul {
  list-style: none;
}
.container {
  max-width: 1270px;
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;
}
.content-section {
  margin-bottom: 120px;
}

.title {
  font-family: Oswald,sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 59px;
}
.title-equipment {
  text-align: center;
  padding-bottom: 40px;
  padding-top: 80px;
}
.title-services {
  text-align: center;
  padding-bottom: 40px;
}

.about-us {
  text-align: center;
  max-width: 1026px;
  margin: auto;
  &__description {
    font-size: 24px;
    line-height: 29px;
    padding: 16px 0 64px;
    text-align: justify;
  }
}
.feedback-form-title {
  text-align: center;
  margin-bottom: 56px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -16px;
    height: 3px;
    background-color: #AFCA0B;
    width: 111px;
    left: calc(50% - 111px/2);
  }
}

@media screen and (max-width: 600px) {
  .about-us {
    &__description {
      font-size: 16px;
      line-height: 22px;
      padding: 16px 0 24px;
      text-align: justify;
    }
  }
  .content-section {
    margin-bottom: 60px;
  }
}