.feedback-form {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    margin: auto;
    &__item {
        margin-bottom: 31px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        padding-bottom: 6px;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.01em;
        color: #999999;
        opacity: 0.5;
        border: none;
        transition: opacity .3s, color .3s;
        border-bottom: 1px solid #111111;
        &:focus {
            color: #111111;
            outline: none;
            opacity: 0.9;
        }
    }
    .btn {
        width: 198px;
        margin: auto;
    }
}