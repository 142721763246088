.header {
    background: #31313160;
    position: fixed;
    width: 100%;
    transition: all .3s;
    z-index: 10;
    top: 0;
}

.header-wrapper {
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 1200px) {
    .header-wrapper {
        padding: 20px 0;
    }
}
@media screen and (max-width: 770px) {
    .header {
        height: 86px;
       
    }
    .header-mob_open {
        height: 100% !important;
        background: #313131ea;
        z-index: 20;
        .nav_open {
            position: absolute;
            width: 100%;
            top: 30%;
            left: 0;
            display: flex;
            justify-content: center;
            .nav-list {
                align-items: flex-start;
                flex-direction: column;
                &__item {
                    padding: 0;
                    margin: 0;
                    padding: 20px 0;
                    font-size: 24px;
                }
            }
        }
    }
}

@media screen and (max-width: 570px) {
    .header-wrapper {
        padding: 10px 0;
    }
    .header {
        height: 48px;
       img {
           padding-top: 2px;
           width: 170px;
       }
    }
}