.modal {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(17, 17, 17, 0.4);
    z-index: 19;
}
.modal-content {
    position: fixed;
    top: 120px;
    left: 25%;
    right: 25%;
    width: 50%;
}

.modal-content__close {
    position: absolute;
    right: -82px;
    background-color: transparent;
    border: none;
    top: -10px;
    outline: none;
    cursor: pointer;
    &:hover {
        opacity: .8;
    }
}

@media screen and (max-width: 1200px) {
    .modal-content {
        left: 15%;
        right: 15%;
        width: 70%;
        
    }
}
@media screen and (max-width: 600px) {
    .modal-content {
        top: calc(50% - 320px/2);
    }
    .modal-content__close {
        right: -30px;
        top: -30px;
       img {
            width: 30px;
            height: 30px;
       }
    }
}