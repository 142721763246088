.btn {
    font-family: Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    padding: 14px 0;
    border: none;
    cursor: pointer;
    background: #AFCA0B;
    border-radius: 4px;
    outline: none;
    &:active {
        transform: scale(.95);
    }
    &:disabled {
        background: #999;
        opacity: .6;
        &:hover {
            cursor: default;
            box-shadow: none;
        }
    }
    transition: all .3s, transform .2s;
    &-sm {
        min-width: 198px;
    }
    &-md {
        min-width: 302px;
    }
    &:hover {
        box-shadow:  -4px -4px 14px #AFCA0B,
                      4px 4px 14px #AFCA0B;
    }
}