.hero {
  width: 100vw;
  height: 100vh;
  background: url('../../assets/vacancies-hero.jpg') no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.heroText {
  margin: 0 auto;
  color: #fff;
  max-width: 704px;
  text-align: center;
  font-size: 24px;

  a {
    display: block;
    margin: 36px auto 0 auto;
    padding: 16px 50px;
    cursor: pointer;
    max-width: 300px;
    background-color: #AFCA0B;
    color: #000;
    font-size: 24px;
    font-weight: 600;
    font-family: Oswald, sans-serif;
  }

  h1 {
    margin-bottom: 50px;
    font-size: 72px;
    text-transform: uppercase;
  }
}

.vacancies {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  margin: 80px 0;

  @media screen and (max-width: 1000px) {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}