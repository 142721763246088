.footer {
    background-color: #111111;
    padding: 64px 0 77px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    .container {
        width: 100%;
    }
    .nav-list {
        padding: 0 100px;
        display: flex;
        justify-content: center;
        
        padding-bottom: 46px;
    }
}
.contacts {
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__address {
        font-style: normal;
    }
    &__phone a,
    &__mail a{
        color: #ffffff;
        text-decoration: none;
        border-bottom: 2px solid transparent;
        padding: 3px 4px;
        transition: border .3s;
        &:hover,
        &.active {
            border-color: #ffffff;
        }
    }
}

@media screen and (max-width: 930px) {
    .contacts {
        flex-direction: column;
        padding: 0;
        font-size: 18px;
        &__address {
            text-align: center;
            padding-bottom: 20px;
        }
        &__phone {
            padding-bottom: 20px;
        }
    }
}